/**
 * array 오름차순 정렬
 * @returns 오름차순으로 정렬된 array 반환
 */
Array.prototype.ascending = function () { return this.sort((a, b) => { return a < b ? -1 : a > b ? 1 : 0 }) }

/**
 * 오브젝트 array 오름차순 정렬
 * @param {String} sortKey : 정렬키 값
 * @returns 오름차순으로 정렬된 오브젝트 array 반환
 */
Array.prototype.ascendingObjectArray = function (sortKey) { return this.sort((a, b) => { return a[sortKey] < b[sortKey] ? -1 : a[sortKey] > b[sortKey] ? 1 : 0 }) }

/**
 * array 내림차순 정렬
 * @returns 내림차순으로 정렬된 array 반환
 */
Array.prototype.dscending = function () { return this.sort((a, b) => { return a > b ? -1 : a < b ? 1 : 0 }) }

/**
 * 오브젝트 array 내림차순 정렬
 * @param {String} sortKey : 정렬키 값
 * @returns 내림차순으로 정렬된 오브젝트 array 반환
 */
Array.prototype.dscendingObjectArray = function (sortKey) { return this.sort((a, b) => { return a[sortKey] > b[sortKey] ? -1 : a[sortKey] < b[sortKey] ? 1 : 0 }) }

/**
 * 리스트를 트리 리스트 구조로 반환
 * @param {*} rootId : 최상위 루트 ID값 (없으면 부모ID 값을 null값으로 체크함)
 * @param {String} parentIdKey : 부모ID에 해당하는 오브젝트 Key
 * @param {String} idKey : ID에 해당하는 오브젝트 Key
 * @returns 
 */
Array.prototype.toTreeList = function (rootId, parentIdKey, idKey) {

    // 트리 리스트 결과 객체
    let resultTreeItems = new Array();

    // 탐색 재귀함수
    // 자신의 부모를 찾을 때 까지 재귀 호출
    let seek = (targetItems, target, index) => {

        if (targetItems instanceof Array) {

            return targetItems.some(item => {

				if (item[idKey] === target[parentIdKey]) {

                    // 대상 아이템
					let treeItem = this.splice(index, 1)[0];

                    // 자식 아이템을 담을 객체 추가
                    treeItem.children = new Array();

                    // 부모 하위에 추가
					return item.children.push(treeItem);
				}

                // 부모를 찾지 못하면 다음 부모 아이템으로 다시 탐색 (재귀호출)
				return seek(item.children, target, index);
			});
        } 
    }

    // 트리 리스트 생성 (대상 리스트 아이템이 0개가 될 때 까지)
    while (this.length > 0) {
        
        this.some((item, index) => {

            let parentId = item[parentIdKey];
            
            // 최상위 루트 아이템을 찾아서 추가
            if (parentId === rootId || parentId === null) {

                // 대상 아이템
                let treeItem = this.splice(index, 1)[0];

                // 자식 아이템을 담을 객체 추가
                treeItem.children = new Array();
                
                // 추가
                return resultTreeItems.push(treeItem);
            }

            // 자식 아이템들은 자신의 부모를 찾아서 추가
            return seek(resultTreeItems, item, index);
        });
    }

    return resultTreeItems;
}

/**
 * array 중복 제거
 * @returns 중복이 제거된 array 반환
 */
Array.prototype.deduplicate = function () { 

    if (this.length > 0) {

        let firstValueType = typeof(this[0]);
        
        // 첫번째 array값 유형이 "object"면 "object array"로 판단 함
        if (firstValueType === "object") return [...new Set(this.map(JSON.stringify))].map(JSON.parse);
        else return [...new Set(this)]
    }
    else return [];
}