/**
 * 문자열 공백여부 반환
 * @returns true/false
 */
String.prototype.isEmpty = function () { return this.trim() === "" ? false : true }

/**
 * 문자열이 같은지 여부 반환
 * @param {String} compareValue : 비교할 값
 * @returns true/false
 */
String.prototype.equals = function (compareValue) { return this.toString() === compareValue }

 /**
  * 문자열이 숫자 값인지 여부 반환
  * @returns true: 숫자 / false: 문자열
  */
String.prototype.isNumber = function () { return !isNaN(Number(this.toString())) }

/**
 * 이메일 주소 형식 여부 반환
 * @returns true/false
 */
String.prototype.isEmailAddress = function () {

    let regularExpression = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return regularExpression.test(this);
}

/**
 * 핸드폰 번호 형식 여부 반환
 * @returns true/false
 */
String.prototype.isCellPhoneNumber = function () {
    
    let regularExpression1 = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;
    let regularExpression2 = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    return regularExpression1.test(this) || regularExpression2.test(this);
}

/**
 * 숫자만 추출하여 반환
 * @returns 추출한 숫자값 반환
 */
String.prototype.extractNumber = function () { return this.replace(/[^0-9]/g, "") }

/**
 * 숫자를 제외한 문자 추출하여 반환
 * @returns 추출한 문자 반환
 */
String.prototype.extractNotNumber = function () { return this.replace(/[0-9]/g, "") }

 /**
 * 소수점 절삭 (반올림, 올림 아님)
 * 공식 : Math.floor(값 * 10의 digits제곱) / 10의 digits제곱
 * @param {Number} digits : 자릿수
 * @returns 
 */
String.prototype.truncDecimalPoint = function (digits) { return (Math.floor(Number(this) * Math.pow(10, digits)) / Math.pow(10, digits)).toString() }

/**
 * 
 * @param {*} param0 
 */
String.prototype.checkPasswordValidation = function ({
    minLength = 0, 
    maxLength = 0, 
    isIncludeUpperCase = true, 
    isIncludeLowerCase = true, 
    isIncludeNumber = true, 
    isIncludeSpecialCharacters = true,
    failMessage = "",
    callback = null} = {}) {

        let isValide = true;

        if (minLength > 0 && this.length >= minLength) isValide = false;
        if (maxLength > 0 && this.length <= maxLength) isValide = false;

        callback(isValide, failMessage)
}