/**
 * store index
 * - state: 전역에서 사용할 상태 값
 * - getters: state 반환
 * - mutations: state 변경 (동기처리) => commit("함수명", "전달인자")
 * - action: state 변경 (비동기처리) => dispatch("함수명", "전달인자")
 * - plugins: 사용할 플러그인 정의
 */

import { createStore } from "vuex";
 
// 공통 모듈
import commonInfo from "./modules/common-info";
 
// 사용자 정보 모듈
import userInfo from "./modules/user-info";
 
export default createStore({
    modules: { commonInfo, userInfo },
});