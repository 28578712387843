/**
 * 사용자 정보 스토어 모듈
 */
 export default {
	state: {
		// 시스템코드
		systemCode: null,
		// 사용자 아이디
		userId: null,
		// 사용자 이름
		userName: null,
		// 이메일
		email: null,
		// 부서코드
		deptCode: null,
		// 초기화여부
		initYN: null,
		// 사용자 직위
		grade: null,
		// 사용자 유형
		useType: null,

	},
	getters: {

		/**
		 * 시스템코드 값 반환 
		 * @param {Object} state : state객체
		 * @returns
		 */
		getSystemCode(state) { return state.systemCode },

		/**
		 * 사용자 아이디 값 반환
		 * @param {Object} state : state 객체
		 * @returns 
		 */
		getUserId(state) { return state.userId },

		/**
		 * 사용자 이름 반환
		 * @param {Object} state : state 객체
		 * @returns 
		 */
		getUserName(state) { return state.userName },

		/**
		 * 사용자 이메일 반환
		 * @param {Object} state : state 객체
		 * @returns 
		 */
		getEmail(state) { return state.email },

		/**
		 * 부서코드 반환
		 * @param {Object} state : state 객체
		 * @returns 
		 */
		getDeptCode(state) { return state.deptCode },

		/**
		 * 로그인여부 반환
		 * @param {Object} state : state 객체
		 * @returns 
		 */
		getInitYN(state) { return state.initYN },

		/**
		 * 사용자직위 반환
		 * @param {Object} state : state 객체
		 * @returns 
		 */
		getGrade(state) { return state.grade },

		/**
		 * 사용자직위 유형
		 * @param {Object} state : state 객체
		 * @returns 
		 */
		getUseType(state) { return state.useType },
	},
	mutations: {

		/**
		 * 시스템코드 설정
		 * @param {Object} state 
		 * @param {String} value 
		 */
		setSystemCode: (state, value) => { state.systemCode = value },

		/**
		 * 사용자 아이디 설정
		 * @param {Object} state : state 객체
		 * @param {String} value 
		 */
		setUserId: (state, value) => { state.userId = value },

		/**
		 * 사용자 이름 설정
		 * @param {Object} state 
		 * @param {String} value 
		 */
		setUserName: (state, value) => { state.userName = value },

		/**
		 * 사용자 이메일 설정
		 * @param {Object} state 
		 * @param {String} value 
		 */
		setEmail: (state, value) => { state.email = value },

		/**
		 * 부서코드 설정
		 * @param {Object} state 
		 * @param {String} value 
		 */
		setDeptCode: (state, value) => { state.deptCode = value },

		/**
		 * 로그인여부 설정
		 * @param {Object} state 
		 * @param {String} value 
		 */
		setInitYN: (state, value) => { state.initYN = value },

		/**
		 * 사용자 직위 설정
		 * @param {Object} state 
		 * @param {String} value 
		 */
		setGrade: (state, value) => { state.grade = value },
		
		/**
		 * 사용자 유형
		 * @param {Object} state 
		 * @param {String} value 
		 */
		setUseType: (state, value) => { state.userType = value },
	},
	actions: {

		/**
		 * 사용자 정보 설정
		 * @param {*} param 
		 * @param {*} userInfo 
		 * @returns 
		 */
		setUserInfo: ({ commit }, userInfo) => {

			return new Promise(resolve => {

				if (userInfo !== null && userInfo !== '') {

					commit('setSystemCode', userInfo.SystCode)
					commit('setUserId', userInfo.EmplID)
					commit('setUserName', userInfo.EmplName)
					commit('setEmail', userInfo.Email)
					commit('setDeptCode', userInfo.DeptCode)
					commit('setInitYN', userInfo.InitYN)
					commit('setGrade', userInfo.Grade)
					commit('setGrade', userInfo.Grade)
					commit('setUseType', userInfo.UseType)
				}
				else {

					commit('setSystemCode', null)
					commit('setUserId', null)
					commit('setUserName', null)
					commit('setEmail', null)
					commit('setDeptCode', null)
					commit('setInitYN', null)
					commit('setGrade', null)
					commit('setUseType', null)
				}

				resolve();
			});
		}
	},
};
