/**
 * 숫자가 같은지 여부 반환
 * @param {Number} compareValue : 비교할 값
 * @returns true/false
 */
Number.prototype.equals = function (compareValue) { return Number(this) === compareValue }

/**
 * 소수점 절삭 (반올림, 올림 아님)
 * 공식 : Math.floor(값 * 10의 digits제곱) / 10의 digits제곱
 * @param {Number} digits : 자릿수
 * @returns 
 */
Number.prototype.truncDecimalPoint = function(digits) { return Math.floor(this * Math.pow(10, digits)) / Math.pow(10, digits) }